import { render, staticRenderFns } from "./MdopEditAppr.vue?vue&type=template&id=152c8830&scoped=true&"
import script from "./MdopEditAppr.vue?vue&type=script&lang=js&"
export * from "./MdopEditAppr.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "152c8830",
  null
  
)

export default component.exports