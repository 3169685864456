<template>
  <div class="vg_wrapper" v-loading="loadingFlag">
    <div class="vd_edhea vg_button_group">
      <editHeader
        :isShow="isShow"
        :btn="btn"
        :strForm="mdopForm"
        @openEdit="openEdit"
        @closeEdit="closeEdit"
        @submit="submit('mdopForm')"
      />
      <el-button type="primary" size="small" class="vd_export" @click="buttonRefresh()" v-if="isShow">刷新</el-button>
    </div>
    <el-form ref="mdopForm" :model="mdopForm" label-width="160px" size="mini" :disabled="isShow">
      <el-row>
        <el-col :md="8">
          <el-form-item label="产品图片" prop="imge_id" label-width="120px" :rules="[{ required: true, trigger: 'blur', message: ' ' }]">
            <el-upload
              class="avatar-uploader"
              :action="uploadUrl"
              :data="dataBody"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <imgPopover v-if="mdopForm.imge_id" :imgeUrl="mdopForm.imge_url" :imgeUrlO="mdopForm.imge_urlO" />
              <i v-else class="el-icon-plus avatar-uploader-icon vg_pointer" />
            </el-upload>
            <span>仅支持jpg、png、jpeg格式且大小不能超过10MB</span>
          </el-form-item>
        </el-col>
        <el-col :md="16">
          <el-row class="vg_mb_5">
            <el-col :md="12">
              <el-form-item label="非毛绒打样单号" prop="requ_no" :rules="[{ required: true, trigger: 'blur', message: ' ' }]">
                <el-input v-model="mdopForm.requ_no" placeholder="请填写非毛绒打样单号" show-word-limit> </el-input>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="开模日期" prop="mdnc_date" :rules="[{ required: true, trigger: 'blur', message: ' ' }]">
                <el-date-picker v-model="mdopForm.mdnc_date" type="date" placeholder="选择开模日期" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mb_5">
            <el-col :md="12">
              <el-form-item label="我司货号" prop="smpl_no" :rules="[{ required: true, trigger: 'blur', message: ' ' }]">
                <el-input v-model="mdopForm.smpl_no" placeholder="请填我司货号" show-word-limit> </el-input>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="计划完成日期" prop="mdnc_pldate" :rules="[{ required: true, trigger: 'blur', message: ' ' }]">
                <el-date-picker v-model="mdopForm.mdnc_pldate" type="date" placeholder="选择计划完成日期" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mb_5">
            <el-col :md="12">
              <el-form-item label="模具编号" prop="mdnc_no" :rules="[{ required: true, trigger: 'blur', message: ' ' }]">
                <el-input disabled v-model="mdopForm.mdnc_no" maxlength="100" placeholder="请填写模具编号" show-word-limit>
                  <template slot="append">
                    <el-link :disabled="isShow" type="primary" @click="importMdinDialog = true" class="empty vg_pointer">导入</el-link>
                  </template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :md="12" prop="mdnc_stff_name">
              <el-form-item label="采购经办人" :rules="[{ required: true, trigger: 'blur', message: ' ' }]">
                <el-select filterable v-model="mdopForm.purchase_id" placeholder="请选择采购人员" size="small" clearable>
                  <el-option v-for="(item, index) in stffType" :key="index" :label="item.stff_name" :value="item.stff_id"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mb_5">
            <el-col :md="12">
              <el-form-item label="模具名称" prop="mdnc_name" :rules="[{ required: true, trigger: 'blur', message: ' ' }]">
                <el-input v-model="mdopForm.mdnc_name" placeholder="请填写模具名称" show-word-limit> </el-input>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="备注" prop="mdnc_ask">
                <el-input v-model="mdopForm.mdnc_ask" placeholder="请填写" show-word-limit> </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mb_5">
            <el-col :md="12" prop="mdnc_stff_name">
              <el-form-item label="外销员" :rules="[{ required: true, trigger: 'blur', message: ' ' }]">
                <el-select filterable v-model="mdopForm.export_id" placeholder="请选择采购人员" size="small" clearable>
                  <el-option v-for="(item, index) in stffType" :key="index" :label="item.stff_name" :value="item.stff_id"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <div class="vd_dash"></div>
      <el-row class="vg_mb_5">
        <el-col>
          <!--          <div class="vg_mb_16">
            <el-button type="primary" plain size="small" @click="addSubTableRow">新增</el-button>
            <el-button type="danger" plain size="small" @click="delSubTableRow">删除</el-button>
          </div>-->
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="24">
          <mdopPartList
            :suppList="suppList"
            ref="subTable"
            :parentForm="mdopForm"
            @handleSelectionChange="handleSelectionChange"
          ></mdopPartList>
        </el-col>
      </el-row>
      <el-row class="vg_mt_16 vd_bortop">
        <el-col>
          <inputUser :isCookie="true" ref="userData"></inputUser>
        </el-col>
      </el-row>
      <el-dialog @opened="getTableData" title="导入模具信息库" :visible.sync="importMdinDialog" width="50%">
        <el-button
          class="vg_mb_8"
          type="primary"
          size="small"
          @click="
            importMdinDialog = false;
            jump(`/mdin_add?perm_id=129`);
          "
          >新增模具信息</el-button
        >
        <search-table
          ref="searchTable"
          :data="tableData"
          :tableRowKey="'mdin_id'"
          :columns="tableProperties"
          v-loading="loadFlag"
          :totalPage="totalPage"
          @row-dblclick="dbClickJp"
          @getTableData="getTableData"
        >
        </search-table>
      </el-dialog>
    </el-form>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { mdopAPI } from '@api/modules/mdop';
import { imgeAPI } from '@api/modules/imge';
import { custAPI } from '@api/modules/cust';
import { suppAPI } from '@api/modules/supp';
import { userAPI } from '@api/modules/user';
import editHeader from '@/views/component/editHeader';
import inputUser from '@/views/component/inputUser';
import imgPopover from '@/views/component/imgPopover';
import mdopPartList from '@/views/MouldManagement/MdopManage/TabChild/SubTable/MdopPartList.vue';
import { tableProperties } from '@/views/MouldManagement/MdopManage/mdop';
import { mdinAPI } from '@api/modules/mdin';
import SearchTable from '@/components/SearchTableV2.vue';
import { deleteTableRow } from '@assets/js/arrayUtils';
import { cloneDeep } from 'lodash';

export default {
  name: 'mdopEditMain',
  components: {
    SearchTable,
    editHeader,
    inputUser,
    imgPopover,
    mdopPartList
  },
  data() {
    return {
      totalPage: 0,
      loadFlag: false,
      tableData: [],
      tableProperties: tableProperties,
      importMdinDialog: false,
      mdopForm: {
        imge_id: null,
        imge_url: null,
        imge_urlO: null,
        mdnc_part_list: []
      },
      stffForm: {},
      isShow: true,
      btn: {},
      uploadUrl: this.helper.modePrepend(imgeAPI.uploadImage),
      dataBody: {
        file: /\.(png|jpg|gif|jpeg)$/,
        cut: true,
        keep: false,
        x: 800,
        y: 800
      },
      imgForm: {
        imge_id: null,
        imge_url: null,
        imge_urlO: null
      },
      loadingFlag: false,
      mouldTypeList: [
        { id: 0, label: '生产模:P' },
        { id: 1, label: '样品模:S' }
      ],
      custList: [],
      mouldProdList: [
        { id: 0, label: '模房' },
        { id: 1, label: '工厂' }
      ],
      mdopKindList: [
        { id: 0, label: '橡胶成型模:01' },
        { id: 1, label: '橡胶硫化模:02' },
        { id: 2, label: '注塑模:03' },
        { id: 3, label: '热合模:04' },
        { id: 4, label: '辅助工具:05' }
      ],
      mouldTextList: [
        { id: 1, label: '橡胶:01' },
        { id: 2, label: 'PA6:02' },
        { id: 3, label: 'TPR:03' },
        { id: 4, label: 'TPE:04' },
        { id: 5, label: 'TPU:05' },
        { id: 6, label: 'PP:06' },
        { id: 7, label: 'ABS:07' },
        { id: 8, label: 'PET:08' },
        { id: 9, label: '吹塑EVA:09' },
        { id: 10, label: '射出EVA:10' }
      ],
      surfaceList: [
        { id: '0', label: '光面' },
        { id: '1', label: '哑面' },
        { id: '2', label: '晒纹' }
      ],
      stffType: [],
      suppList: [],
      subTableMap: new Map(),
      backupTable: []
    };
  },
  created() {
    this.initData();
  },
  watch: {},
  methods: {
    async getTableData() {
      await (this.loadFlag = true);
      await mdinAPI.getMdinsToMdnc(this.$refs.searchTable.searchForm).then(({ data }) => {
        this.tableData = data.list;
        this.totalPage = data.total;
        this.loadFlag = false;
      });
    },
    dbClickJp(row) {
      let tempObj = {};
      for (const key in row) {
        tempObj[key.replaceAll('mdin', 'mdnc')] = row[key];
      }
      tempObj.isImport = true;
      tempObj.mdnc_id = this.mdopForm.mdnc_id;
      tempObj.status = this.mdopForm.status;
      tempObj.mdnc_date = tempObj.opmd_date;
      tempObj.mdnc_pldate = tempObj.comp_date;
      tempObj.date = tempObj.opmd_date;
      tempObj.smpl_no = tempObj.prod_no;
      tempObj.moldinfoLists.forEach(item => {
        if (item.mdnc_part_attr.indexOf('/') !== -1) {
          item.mdnc_part_attr = item.mdnc_part_attr.split('/');
        } else if (item.mdnc_part_attr) {
          item.mdnc_part_attr = [item.mdnc_part_attr];
        }
      });
      tempObj.mdnc_part_list = tempObj.moldinfoLists;
      tempObj.imge_urlO = this.helper.picUrl(tempObj.imge_url, 'l', 0);
      tempObj.imge_url = this.helper.picUrl(tempObj.imge_url, 's', 0);
      this.mdopForm = tempObj;
      this.importMdinDialog = false;
    },
    initData() {
      this.loadingFlag = true;
      this.getmdopInfo();
      this.getStffType();
      //this.getCustList()
      this.getSuppList();
    },
    //启用编辑
    openEdit() {
      this.isShow = false;
      this.$emit('isShow', this.isShow);
    },
    //撤销编辑
    closeEdit() {
      this.$confirm('是否撤销编辑?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.isShow = true;
          this.show = true;
          this.$emit('isShow', this.isShow);
          this.initData();
          this.$message({
            type: 'info',
            message: '已撤销编辑!'
          });
        })
        .catch(() => {});
    },
    //提交表单
    submit(formName) {
      this.$confirm('确认提交?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$refs[formName].validate(valid => {
            if (valid) {
              this.saveInfo();
            } else {
              return false;
            }
          });
        })
        .catch(err => console.error(err));
    },
    // 保存
    saveInfo() {
      let mdopForm;
      mdopForm = JSON.parse(JSON.stringify(this.mdopForm));
      mdopForm.mdnc_part_list = mdopForm.mdnc_part_list.concat(Array.from(this.subTableMap.values()));
      mdopForm.mdnc_part_list.forEach(item => {
        item.mdnc_part_attr = item.mdnc_part_attr.join('/');
        item.mdnc_part_skin = item.mdnc_part_skin.join(',');
      });
      if (mdopForm.isImport) {
        mdopForm.mdnc_part_list = mdopForm.mdnc_part_list.concat(this.backupTable);
      }
      this.subTableMap = new Map();
      this.backupTable = [];
      post(mdopAPI.editMdop, mdopForm)
        .then(res => {
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: '保存成功'
            });
            this.isShow = true;
            this.$emit('isShow', this.isShow);
            this.initData();
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch(err => console.error(err));
    },
    //取消
    cancel() {
      this.$confirm('确定取消新增?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.resetForm('mdopForm');
          this.$router.go(-1);
          this.$message({
            type: 'info',
            message: '已取消新增!'
          });
        })
        .catch(() => {});
    },
    // 刷新按钮
    buttonRefresh() {
      this.initData();
    },
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 成功上传
    handleAvatarSuccess(res) {
      if (res.code === 0) {
        this.mdopForm.imge_id = res.data.imge_id;
        let updateTime = JSON.parse(JSON.stringify(new Date().getTime()));
        let urlEng = JSON.parse(JSON.stringify(res.data.imge_url));
        this.mdopForm.imge_url = this.helper.picUrl(urlEng, 's', updateTime);
        this.mdopForm.imge_urlO = this.helper.picUrl(urlEng, 'l', updateTime);
      } else {
        this.$message.error(res.data.msg);
      }
    },
    // 上传头像前
    beforeAvatarUpload() {},
    // 获取委托人
    getStffType() {
      get(userAPI.getAllUsersV1)
        .then(res => {
          if (res.data.code === 0) {
            let stffType = res.data.data;
            let map = new Map();
            for (let item of stffType) {
              map.set(item.stff_name, item);
            }
            stffType = [...map.values()];
            this.stffType = stffType;
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch(err => console.error(err));
    },
    // 获取客户简称
    getCustList() {
      get(custAPI.getAllCustsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.custList = res.data.data.list;
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch(err => console.error(err));
    },
    // 获取供应商
    getSuppList() {
      get(suppAPI.getSuppsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.suppList = res.data.data;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(err => console.error(err));
    },
    // 获取表单信息
    getmdopInfo() {
      this.mdopForm.mdnc_id = this.$route.query.form_id;
      get(mdopAPI.getMdopById, { mdnc_id: this.mdopForm.mdnc_id })
        .then(res => {
          if (res.data.code === 0) {
            this.mdopForm = res.data.data.form;
            let urlEng = JSON.parse(JSON.stringify(this.mdopForm.imge_url));
            let updateTime = JSON.parse(JSON.stringify(new Date().getTime()));
            this.mdopForm.imge_url = this.helper.picUrl(urlEng, 's', updateTime);
            this.mdopForm.imge_urlO = this.helper.picUrl(urlEng, 'l', updateTime);
            if (this.show) {
              this.mdopForm.imge_id = this.imgForm.imge_id;
              this.mdopForm.imge_url = this.imgForm.imge_url;
              this.mdopForm.imge_urlO = this.imgForm.imge_urlO;
              this.show = false;
            }
            this.imgForm.imge_url = JSON.parse(JSON.stringify(this.mdopForm.imge_url));
            this.imgForm.imge_id = JSON.parse(JSON.stringify(this.mdopForm.imge_id));
            this.imgForm.imge_urlO = JSON.parse(JSON.stringify(this.mdopForm.imge_urlO));
            this.btn = res.data.data.btn;
            this.stffForm.stff_name = this.mdopForm.stff_name;
            this.stffForm.dept_name = this.mdopForm.dept_name;
            this.stffForm.dept_team_name = this.mdopForm.dept_team_name;
            this.stffForm.user_id = this.mdopForm.user_id;
            this.stffForm.dept_id = this.mdopForm.dept_id;
            this.stffForm.stff_id = this.mdopForm.stff_id;
            this.mdopForm.mdnc_part_list?.forEach(item => {
              if (item.mdnc_part_attr.indexOf('/') !== -1) {
                item.mdnc_part_attr = item.mdnc_part_attr.split('/');
              } else {
                item.mdnc_part_attr = [item.mdnc_part_attr];
              }

              if (('' + item.mdnc_part_skin).indexOf(',') !== -1) {
                item.mdnc_part_skin = item.mdnc_part_skin.split(',');
              } else if (item.mdnc_part_skin) {
                item.mdnc_part_skin = [item.mdnc_part_skin];
              }
            });
            this.backupTable = Array.from(this.mdopForm.mdnc_part_list, ({ mdnc_part_id }) => {
              return {
                mdnc_part_id: mdnc_part_id,
                destroy_flag: 1
              };
            });
            setTimeout(() => {
              this.loadingFlag = false;
            }, 500);
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch(err => console.error(err));
    },
    // 子表勾选值
    handleSelectionChange(row) {
      this.selectList = JSON.parse(JSON.stringify(row));
    },
    delSubTableRow() {
      if (this.selectList.length === 0) return this.$message.warning('请至少选择一条数据!');
      let { tableData, map } = deleteTableRow('mdnc_part_id', this.mdopForm.mdnc_part_list, this.subTableMap, this.selectList);
      this.mdopForm.mdnc_part_list = tableData;
      this.subTableMap = map;
    },
    // 删除行
    deleteRow() {
      let temp = [];
      for (let i = 0; i < this.selectList.length; i++) {
        for (let j = 0; j < this.mdopForm.mdnc_part_list.length; j++) {
          if (this.selectList[i].key === this.mdopForm.mdnc_part_list[j].key) {
            temp.push(j);
          }
        }
      }
      temp = temp.sort((a, b) => {
        return b - a;
      });
      for (let i of temp) {
        this.mdopForm.mdnc_part_list.splice(i, 1);
      }
      for (let i = 0; i < this.mdopForm.mdnc_part_list.length; i++) {
        this.mdopForm.mdnc_part_list[i].key = Number(i);
      }
    },
    // 子表新增一行空数据并增加标识
    addSubTableRow() {
      this.mdopForm.mdnc_part_list.push({
        key: this.mdopForm.mdnc_part_list.length,
        mdnc_part_attr: []
      });
      for (let i = 0; i < this.mdopForm.mdnc_part_list.length; i++) {
        this.mdopForm.mdnc_part_list[i].key = Number(i);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader ::v-deep .el-upload--text {
  width: 136px;
  height: 136px;
}
.avatar-uploader .el-upload:hover {
  border-color: $color-primary;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: $color-uploader-icon;
  width: 136px;
  height: 136px;
  line-height: 136px;
  text-align: center;
}
.avatar {
  width: 136px;
  height: 136px;
  display: block;
}
.vd_bortop {
  border-top: #dcdfe6 solid 1px;
  padding-top: 20px;
}
.vd_solidDiv {
  border-top: #dcdfe6 solid 1px;
  margin: 20px 0;
}
.vd_vis {
  visibility: hidden;
}
.vd_dis {
  display: flex;
  justify-content: center;
}
.vd_export {
  position: absolute;
  top: 0;
  left: 65px;
}
</style>
